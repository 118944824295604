import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicYourOpinion = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query usePrismicYourOpinion {
       allPrismicContactus(filter: {tags: {in: "contact_us"}}) {
        nodes {
          lang
          data {
            activity
            buttonno
            buttonyes
            captcha
            city
            contacts
            email1
            namesurname
            phone1
            questionproblem
            rate
            sendbutton
            header
            description3 {
              richText
            }
            kindofservice {
              service
            }
            rategroup {
              rate_1
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicContactus.nodes.filter(item => item.lang === newLang)[0].data);
};

const sanitize = (response) => {
    return {
        header: response.header,
        activity: response.activity,
        buttonNo: response.buttonno,
        buttonYes: response.buttonyes,
        email: response.email1,
        captcha: response.captcha,
        city: response.city,
        contacts: response.contacts,
        nameSurname: response.namesurname,
        phone: response.phone1,
        questionProblem: response.questionproblem,
        rate: response.rate,
        sendButton: response.sendbutton,
        description: response.description3.richText,
        kindOfService: response.kindofservice.map(service => service.service),
        rateGroup: response.rategroup.map(rate => rate.rate_1),
    }
}

export default usePrismicYourOpinion;