import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Text, Stack, Button, useToast } from "@chakra-ui/react";
import { InputField } from "../../components/inputfield";
import { TextareaField } from "../../components/textareafield";
import http from "../../http";

const SANITIZED_LABELS = {
  fullName: "Անուն Ազգանուն",
  email: "Էլ․ փոստ",
  phoneNumber: "Բջջային հեռախոսահամար",
  suggestionToImprove:
    "Ձեր կարծիքով ինչպե՞ս կարող ենք բարելավել Գրասենյակի կողմից մատուցված ծառայությունների որակը։",
  haveYouEverUseOurServices:
    "Օգտվե՞լ եք Հաշտարարի գրասենյակի ծառայություններից։",
  whatKindOfServices:
    "Խնդրում ենք նշել՝ ինչպիսի ծառայություն եք ստացել Հաշտարարի գրասենյակում։",
  rate: "Ինչպե՞ս կգնահատեք Հաշտարարի գրասենյակի կողմից մատուցված ծառայությունները։",
  areRight:
    "Արդյո՞ք Հաշտարարի կողմից պահանջի քննության ժամկետը համապատասխանել է Ձեր սպասելիքներին։",
};

const OpinionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      haveYouEverUseOurServices: null,
      whatKindOfServices: [],
      rate: "",
      areRight: null,
    },
  });
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const onServiceChange = (newVal) => {
    let newServices = getValues("whatKindOfServices");
    if (newServices.includes(newVal)) {
      newServices = newServices.filter((v) => v !== newVal);
    } else {
      newServices.push(newVal);
    }

    setValue("whatKindOfServices", newServices);
  };

  const onSubmit = (values) => {
    if (
      values.haveYouEverUseOurServices === null ||
      values?.whatKindOfServices?.length === 0 ||
      values.rate === "" ||
      values.areRight === null
    ) {
      return toast({
        status: "error",
        title: "Խնդրում ենք լրացնել բոլոր * դաշտերը",
      });
    }

    const data = {
      FORM: "YOUR OPINION",
    };
    Object.entries(values).forEach(([key, value]) => {
      if (value !== null && value !== "") {
        data[SANITIZED_LABELS[key]] = Array.isArray(value)
          ? value.join(", ")
          : value;
      }
    });

    setIsLoading(true);
    http
      .post("/send-email", { data })
      .then(() => {
        toast({
          status: "success",
          title: "Նամակը հաջողությամբ ուղարկվել է",
        });
      })
      .catch((e) => {
        toast({
          status: "error",
          title: e?.message || "Network error",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box
        pb="1.875rem"
        borderBottom="1px"
        borderColor="border.100"
        mb="4"
        maxW="29.5rem"
      >
        <Text mb="6">{"Կոնտակտային տվյալներ (ըստ ցանկության)"}</Text>
        <Stack spacing={3}>
          <InputField label={"Անուն Ազգանուն"} {...register("fullName")} />
          <InputField label={"Էլ․ փոստ"} {...register("email")} />
          <InputField
            label={"Բջջային հեռախոսահամար"}
            {...register("phoneNumber")}
            type="number"
          />
        </Stack>
      </Box>
      <Box
        pb="1.875rem"
        borderBottom="1px"
        borderColor="border.100"
        mb="1.875rem"
      >
        <Text mb="4">
          {"Օգտվե՞լ եք Հաշտարարի գրասենյակի ծառայություններից։*"}
        </Text>
        <Stack
          direction={{
            base: "column",
            sm: "row",
          }}
          spacing="1rem"
          maxW="26rem"
        >
          <Button
            variant={
              watch("haveYouEverUseOurServices") === true
                ? "green"
                : "transparent"
            }
            w="full"
            onClick={() => setValue("haveYouEverUseOurServices", true)}
          >
            {"Այո"}
          </Button>
          <Button
            variant={
              watch("haveYouEverUseOurServices") === false
                ? "green"
                : "transparent"
            }
            w="full"
            onClick={() => setValue("haveYouEverUseOurServices", false)}
          >
            {"Ոչ"}
          </Button>
        </Stack>
      </Box>
      <Box pb="1.875rem" borderBottom="1px" borderColor="border.100" mb="4">
        <Box mb="4">
          <Text>
            Խնդրում ենք նշել՝ ինչպիսի ծառայություն եք ստացել Հաշտարարի
            գրասենյակում։*
          </Text>
        </Box>
        <Stack
          maxW={{
            base: "full",
            sm: "39.5rem",
          }}
          direction={{
            base: "column",
            sm: "row",
          }}
          spacing="1rem"
          mb="1rem"
        >
          {[
            "Ստացել եմ առաջնային խորհրդատվություն",
            "Մասնագետների օգնությամբ ներկայացրել եմ բողոք-պահանջ կազմակերպությանը",
            "Ներկայացրել եմ պահանջ-դիմում Հաշտարարին",
          ]?.map((item, idx) => (
            <Button
              key={`kindofservice-${item}-${idx}`}
              height="100%"
              minH="100px"
              maxW={{
                base: "full",
                sm: "201px",
              }}
              whiteSpace="normal"
              variant={
                watch("whatKindOfServices").includes(item)
                  ? "green"
                  : "transparent"
              }
              onClick={() => onServiceChange(item)}
            >
              {item}
            </Button>
          ))}
        </Stack>
      </Box>
      <Box
        pb="1.875rem"
        borderBottom="1px"
        borderColor="border.100"
        mb="1.875rem"
      >
        <Text mb="4">
          {
            "Ինչպե՞ս կգնահատեք Հաշտարարի գրասենյակի կողմից մատուցված ծառայությունները։*"
          }
        </Text>
        <Stack
          direction={{
            base: "column",
            sm: "row",
          }}
          spacing="1rem"
          maxW="53.25rem"
        >
          {["Գերազանց", "Լավ", "Միջին", "Վատ"]?.map((item, idx) => (
            <Button
              key={`rate-${item}-${idx}`}
              variant={watch("rate") === item ? "green" : "transparent"}
              w="full"
              onClick={() => setValue("rate", item)}
            >
              {item}
            </Button>
          ))}
        </Stack>
      </Box>
      <Box
        pb="1.875rem"
        borderBottom="1px"
        borderColor="border.100"
        mb="1.875rem"
      >
        <Text mb="4">
          {
            "Արդյո՞ք Հաշտարարի կողմից պահանջի քննության ժամկետը համապատասխանել է Ձեր սպասելիքներին։*"
          }
        </Text>
        <Stack
          direction={{
            base: "column",
            sm: "row",
          }}
          spacing="1rem"
          maxW="26.125rem"
        >
          <Button
            variant={watch("areRight") === true ? "green" : "transparent"}
            w="full"
            onClick={() => setValue("areRight", true)}
          >
            {"Այո"}
          </Button>
          <Button
            variant={watch("areRight") === false ? "green" : "transparent"}
            w="full"
            onClick={() => setValue("areRight", false)}
          >
            {"Ոչ"}
          </Button>
        </Stack>
      </Box>
      <Box pb="1.875rem" mb="1.875rem">
        <Text mb="4">
          {
            "Ձեր կարծիքով ինչպե՞ս կարող ենք բարելավել Գրասենյակի կողմից մատուցված ծառայությունների որակը։"
          }
        </Text>
        <Box maxW="39.5rem">
          <TextareaField {...register("suggestionToImprove")} />
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            w="full"
            variant="green"
            type="submit"
            mt={4}
          >
            {"Ուղարկել"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OpinionComponent;
