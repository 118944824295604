import * as React from 'react'
import { Container, Heading } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import Seo from '../../components/SEO'
import Main from '../../components/layouts/Main'
import SmallNav from '../../components/smallNav'
import OpinionComponent from "../../components/yourOpinion/opinionCopm"
import usePrismicYourOpinion from "../../hooks/usePrismicYourOpinion";


const YourOpinion = () => {
  const { language } = useTranslation()
  const data = usePrismicYourOpinion()

  return(
    <Main>
      <Container
        maxW="7xl"
        px="10px"
        mb="3.75rem">
        <Seo
          title={data.header}
          description={data.header}
          link={`https://www.fsm.am/${language}/transfers`}
        />
        <SmallNav>
          {data.header}
        </SmallNav>
        <Heading
          as="h1"
          fontSize={{base: '2xl', sm: "3xl"}}
          fontWeight="900"
          mt={{base: '3', sm: "5"}}
          mb="10">
          {data.header}
        </Heading>
        <OpinionComponent data={data} />
      </Container>
    </Main>
  )
}

export default YourOpinion

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`